@import 'src/design-system/styles/typography.mixins';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.menu {
  border: none;
  flex-wrap: wrap;
  padding: 0;
  gap: 8px;
}

.menuOption {
  flex-grow: 0;
}

.providersList {
  @include list-styles-reset;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.provider {
  padding: 0;
}
