@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.root {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  color: var(--text-10);
  background: var(--shape-10);
  border-radius: 8px;

  &:hover {
    background: var(--primary-90);
    color: var(--text-10);
  }

  &:not(.active):not(:hover) .additionalName {
    color: var(--text-20);
  }
}

.active {
  background: var(--button-cta-default);
  color: var(--text-100);
  cursor: default;

  &:hover {
    background: var(--button-cta-default);
    color: var(--text-100);
  }
}

.name {
  @include font-style-default($font-weight-bold);
}

.additionalName {
  @include font-style-tiny();
  line-height: $line-height-extra-small;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;

  padding: 4px;
  min-width: 42px;
  width: 42px;
  height: 42px;

  color: var(--static-gray-40);
  background: var(--shape-00);
  border-radius: 4px;

  & img {
    width: 100%;
  }
}
