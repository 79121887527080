@import 'src/design-system/styles/typography.mixins';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.stubImage {
  margin: 12px 12px 20px 12px;
}

.title {
  @include font-style-h4($font-weight-medium);
  color: var(--text-10);
  margin: 0;
}

.description {
  @include font-style-small;
  color: var(--text-20);
  margin: 0;
}
