@import 'src/design-system/styles/typography.mixins';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.info {
  @include font-style-small;
  text-align: center;
  color: var(--text-20);
}
