@import 'src/design-system/styles/typography.mixins';

.modalBody {
  @include font-style-default;

  padding: 8px 24px 14px;
}

.footerButton {
  min-width: 88px;
}
