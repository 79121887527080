@import 'src/design-system/styles/typography.mixins';

.container {
  font-family: inherit;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--shape-10);

  & .icon {
    margin-bottom: 40px;
  }

  & h4 {
    @include font-style-subtitle($font-weight-bold);
    text-align: center;

    max-width: 280px;

    margin: 0 0 4px 0;
  }
}
